import Vue from 'vue'

var objects = [
    "V1/Scoreboard",
    "V2/Scoreboard",
    "V2/NextFights",
];

objects.forEach(element => {
    var name = element.includes("/") ? element.split("/")[element.split("/").length - 1] : element;
    Vue.component(name+element.split("/")[0], () => import(`../components/${element}.vue`))
});